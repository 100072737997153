h1 {
    @media ($mobile) {
        font-size: 24px;
        line-height: 1.2;
    }
}


a {
    color: inherit;
    text-decoration: none;
}

.image-link {
  cursor: zoom-in;
}

button:not(.mfp-close) {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: $red;
    border: none;
    border-radius: 6px;
    outline: none;
    box-shadow: $shadow;
    color: $white;
    font-size: 18px;
    justify-content: center;
    line-height: 1;

    &:hover {
        background-color: darken($red, 2%);
    }

}

input[type="text"] {
    outline: none;
    height: 40px;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 6px;
    padding: 0 20px;
}

.header {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;

        @media ($mobile) {
            flex-direction: column;
        }
    }


    &__left {
        flex-shrink: 0;
        max-width: 350px;

        @media ($tablet) {
            max-width: 250px;
        }

        @media ($mobile) {
            /*max-width: 180px;*/
            max-width: 100%;
        }

    }

    &__logo {}

    &__center {
        font-size: 20px;
        margin: 0 20px;
        text-align: center;

        @media ($mobile) {
            /*display: none;*/
            font-size: 15px;
            display: flex;
            margin: 0;
            text-align: left;

            > div:not(:last-child) {
            	white-space: nowrap;
            	&:after {
            		content: "";
            		margin-right: 5px;
            	}
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    &__phone {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 6px;
        display: block;
        color: $blue;

        @media ($mobile) {
            font-size: 24px;
        }
    }

    &__time {
        font-size: 14px;
    }

    &__btn {
        margin-top: 5px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    &__bottom {
        background: $blue;
        color: $white;
        display: flex;
        justify-content: center;
        height: 50px;
        align-items: center;
    }

    &__nav {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    &__link {
        margin: 0 5px;
        font-size: 18px;


        @media ($mobile) {
          font-size: 16px;
          &._att {
            /*width: 100%;*/
          }

        }

    }
}


.top {
    background-size: cover;
    background-repeat: no-repeat;
    /*background-position: right -50px;*/
    padding-top: 30px;
    padding-bottom: 80px;

    @media($tablet) {
    	background-position: calc(100% + 80px) -50px;
    }
    @media(max-width: 1600px) {
        background-position: calc(100% + 280px);
    }

    @media ($mobile) {
        background-position: calc(100% + 280px) calc(100% + 142px);
        padding-top: 20px;
        padding-bottom: 40px;
        background-image: url(../img/main_background.jpg) !important;
        background-repeat: repeat;
        background-size: auto;
        background-color: rgba($blue, .1);
    }

    &__row {
        display: flex;
    }

    &__text {
        max-width: 60%;
        padding-bottom: 30px;

        @media ($mobile) {
            max-width: 100%;
            padding-bottom: 10px;
        }

        h1 {
            line-height: 1.15;
            font-size: 40px;
            margin-bottom: 10px;

            @media ($mobile) {
                font-size: 22px;
            }

            span {
                color: $red;
                /*color: $white;*/
                /*padding: 0 5px;*/
                margin-bottom: 8px;
                display: inline-block;

                @media ($mobile) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &__list {
        font-size: 20px;
        /*display: flex;*/
    }

    &__list-item {
        margin-right: 10px;
        display: flex;
        align-items: center;

        @media ($mobile) {
            font-size: 16px;
            margin-bottom: 7px;
        }

        svg {
            fill: $blue;
            margin-right: 10px;
            position: relative;
        }
    }

    &__form {}
}


.top-form {
    background: $white;
    padding: 20px;
    border-radius: 6px;
    box-shadow: $shadow;
    padding-bottom: 50px;

    &__title {
        text-align: center;
        margin-bottom: 5px;

        @media ($mobile) {
            font-size: 20px;
        }
    }
    &__subtitle {
    	text-align: center;
    	margin-bottom: 20px;
    }

    &__form {
        display: flex;
        justify-content: center;

        @media ($mobile) {
            flex-direction: column;
        }
    }

    &__item {
        margin-right: 20px;

        @media ($mobile) {
            margin-right: 0;
            margin-bottom: 10px;
        }

        input {
            @media ($mobile) {
                width: 100%;
            }
        }
    }

    &__btn {}
}

.attestat{
  padding-bottom: 40px;
  @media ($mobile) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;

    @media ($mobile) {
      margin-bottom: 30px;
    }
  }
  &__row{
    display: flex;
    @media ($mobile) {
      flex-direction: column;
    }

  }
  &__pic{
    width: 33%;
    margin-right: 50px;
    flex-shrink: 0;
    @media ($mobile) {
      margin-right: 0;
      width: 100%;
      margin: auto;
      margin-bottom: 16px;
      align-self: center;
      text-align: center;
    }
  }
  &__text{
    flex-grow: 1;

    a{
      color: $blue;
      text-decoration: underline;
    }

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.advantages {
    padding-top: 70px;
    padding-bottom: 70px;

    @media ($mobile) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__title {
        text-align: center;
        margin-bottom: 50px;
    }

    &__row {
        @include row(20px);
        justify-content: center;

        @media ($mobile) {
            flex-direction: column;
            margin: 0;
        }
    }

    &__caption {
        @media ($mobile) {
            margin-top: 6px;
            font-size: 16px;
            font-weight: normal;
        }
    }

    &__item {
        @include col(3, 20px);
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        @media ($mobile) {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;
        }

        svg {
            fill: $blue;

            @media ($mobile) {
                flex-shrink: 0;
            }
        }
    }
}


/* .attention {
	text-align: center;
	padding: 60px 0;
	background-size: cover;
	background-position: center;
	&__icon {
	}
	&__title {
		text-align: center;
		color: $white;

		strong {
			color: $red;
		}
	}
}
 */

/*.license {
	padding: 50px 0;
	&__title{
		text-align: center;
		margin-bottom: 40px;
	}
	&__row{
		@include row(20px)
		justify-content: center;
	}
	&__col{
		@include col(4, 20px)

	}
}
*/

/*.master {
	padding-bottom: 70px;
	padding-top: 20px;
	background-color: $blue;
	&__title {
		text-align: center;
		color: $white;
	}
}*/

/*.master-form {
	@include row(20px);
	&__item {
		@include col(4, 20px);
		input{
			width: 100%;
		}
	}
	&__btn {
		@include col(4, 20px);
		text-align: center;
		justify-content: center;
	}
}*/

.faq {
    padding: 50px 0;

    @media ($mobile) {
        padding: 40px 0;
    }

    &__title {
        text-align: center;
    }

    &__row {
        @include row(40px);

        @media ($mobile) {
            width: 100%;
            margin: 0;
        }
    }

    &__col {
        @include col(2, 40px);

        @media ($mobile) {
            width: 100%;
            margin: 0;
        }
    }

    &__caption {
        color: $blue;
        line-height: 1.2;

        @media ($mobile) {
            font-size: 18px;
        }
        svg {
            fill: $blue;
            position: relative;
            top: 1px;
            margin-right: 10px;
        }
    }

    &__descr {}
}

.videogallery {
    padding-top: 50px;
    padding-bottom: 50px;

    @media ($mobile) {
        padding-top: 40px;
    }

    &__title {
        text-align: center;
        /*color: $blue;*/
        margin-bottom: 40px;

        @media ($mobile) {
            margin-bottom: 20px;
        }
    }

    &__row {
        @include row(20px);
        justify-content: center;

        @media ($mobile) {
            margin: 0;
        }
    }


    &__item {
        @include col(3, 20px);
        margin-bottom: 20px;

        @media ($tablet) {
        	@include col(2, 20px);
        	margin-bottom: 20px;
        }


        @media ($mobile) {
            width: 100%;
            margin: 0;
            margin-bottom: 15px;
        }
    }
}

.comments {
    padding: 50px 0;

    @media ($mobile) {
        padding-top: 30px;
    }

    &__title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__wrapper {
        @include row(20px);

        @media ($mobile) {
            width: 100%;
            margin: 0;
        }
    }

    &__item {
        @include col(3, 20px);
        padding: 20px;
        box-shadow: $shadow;
        border-radius: 6px;

        @media ($mobile) {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__img {}

    &__name {
        font-size: 20px;
        font-weight: bold;
        color: $blue;
    }

    &__text {
        font-style: italic;
    }
}

.contacts {
    padding: 50px;

    @media ($mobile) {
        padding: 40px 0;
    }

    &__row {
        /*@include row(20px);*/
        /*justify-content: center;*/
        align-items: center;
    }

    &__text {
        /*@include col(2, 20px);*/
        /*text-align: center;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__contacts {
        @include col(3, 20px);
        background-color: $blue;
        color: $white;
        padding: 20px;
        font-size: 20px;
        padding-bottom: 60px;
        text-align: center;
    }

    &__caption {
        text-align: center;
    }

    &__button {
    	@media ($mobile) {
    	    font-size: 17px;
    	}
    }

}


.popup {
    max-width: 400px;
    background-color: $white;
    margin: auto;
    font-size: 16px;
    font-family: Arial, sans-serif;
    border-radius: 6px;
    position: relative;

    &__inner {
        padding: 20px 20px 40px;
    }

    &__title {
        text-align: center;
    }

    .mfp-close {
        background-color: transparent;
    }
}


.popup-form {
    &__item {
        margin-bottom: 15px;

        input {
            width: 100%;
        }
    }

    &__btn {
        width: 100%;
    }
}


.hide-mob {
    @media($mobile) {
        display: none;
    }
}





