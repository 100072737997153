$container-width: 1170px;

/* COLORS */

$black: #000;
$white: #fff;
$red: #ea0b30;
// $blue: #3760dc;
// $blue: #4b639f;
$blue: #30D5C8;
$shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);

/* MEDIA QUERIES */
$tablet: unquote("max-width: 1024px");
$mobile: unquote("max-width: 767px");
